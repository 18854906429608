<template>
  <div class="confirmOrder">
    <div class="address marginBottom">
      <div class="addressInfo" @click="addressClick">
        <div class="info" v-if="addressInfo.appointmentAddress">
          <p class="name-p">
            {{ addressInfo.appointmentPersonName }}
            {{ { 1: "先生", 2: "女士" }[addressInfo.sex] }}
            {{ addressInfo.appointmentPhone }}
          </p>
          <p class="address-p">{{ addressInfo.appointmentAddress }}</p>
        </div>
        <div class="info" v-else>
          <p class="noAddressP">请选择服务地址</p>
        </div>
        <van-icon name="arrow" />
      </div>
      <svg-icon icon-class="line" class="lineIcon"></svg-icon>
    </div>
    <div class="serveDetail marginBottom">
      <div class="serve-div">
        <img :src="details.serveImgUrl" alt="" class="serveImg" />
        <div class="serve-info">
          <p class="serve-name">{{ details.serveName }}</p>
          <p class="purchase_num-p">
            <span class="price">￥{{ details.servePrice }}</span>
            <span style="font-size: .4rem;font-weight: bold;">×1</span>
            <!-- <van-stepper v-model="purchase_num" readonly disable-input button-size="30px"/> -->
          </p>
          <p class="serve-time">
            <van-icon name="clock-o" class="timeIcon" /><span
              >{{ details.serveTime }}分钟</span
            >
          </p>
        </div>
      </div>
      <div
        class="vip-div"
        @click.stop="clickSelectPurchaseVip"
        v-if="!isAlReadyVip"
      >
        <div class="vip-diamond-div">
          <span class="vip-diamond-span"
            ><img src="../../assets/img/diamond.png" alt="" class="diamond" />
            <font>开通会员</font></span
          >
          <span class="vip-tips-span">开通蓝瑟到家会员 优享会员福利</span>
          <span class="vip-discount-span">本单可减<font>{{ (details.servePrice - details.servePrice * vipDiscountRatio).toFixed(2) }}元</font></span>
        </div>
        <div class="vip-select-div">
          <span
            >￥{{ openVipPrice }}
            <van-checkbox
              v-model="isPurchaseVip"
              bind-group
              checked-color="rgb(214, 3, 3)"
              icon-size="18px"
            />
          </span>
          <span>勾选开通</span>
        </div>
      </div>
    </div>
    <div class="serve-select-div marginBottom">
      <p @click="serveUserSelect" class="attendantInfo van-hairline--bottom">
        <span>技师</span
        ><span class="info" :style="{ color: serve_name ? '#000' : 'gray' }"
          >{{ serve_name || "请选择" }}
          <img
            class="serveUrl"
            :src="serve_url"
            v-if="serve_url"
            alt=""/><van-icon name="arrow"
        /></span>
      </p>
      <p @click="serveTimeSelect">
        <span>预约时间</span
        ><span :style="{ color: appointment_time ? 'red' : 'gray' }"
          >{{ appointment_time_title }}{{ appointment_time || "请选择"
          }}<van-icon name="arrow"
        /></span>
      </p>
    </div>
    <div
      class="travel-div marginBottom"
      v-if="addressInfo != '' && travel_id !== ''"
    >
      <div class="travel-select van-hairline--bottom">
        <p class="paddingP">
          <span>出行方式</span>
          <span class="travel-list-div">
            <font
              v-if="busSubwayTraffic.status == 1"
              @click="
                (travel_id = busSubwayTraffic.trafficWay),
                  (travel_price = busSubwayTraffic.trafficAmount)
              "
              :class="[
                travel_id == busSubwayTraffic.trafficWay ? 'travelSelect' : ''
              ]"
              >公交/地铁</font
            >
            <font
              v-if="rentalDidiTraffic.status == 1"
              @click="
                (travel_id = rentalDidiTraffic.trafficWay),
                  (travel_price = rentalDidiTraffic.trafficAmount)
              "
              :class="[
                travel_id == rentalDidiTraffic.trafficWay ? 'travelSelect' : ''
              ]"
              >网约车</font>
          </span>
        </p>
        <!-- <p class="travel-tips">
          注：5公里内公交/地铁可选，距离超出范围默认出租/滴滴来计算
        </p> -->
      </div>
      <p class="paddingP">
        <span>预估出行费用</span><span>￥{{ travel_price }}</span>
      </p>
    </div>
    <div class="vipDiscount-div marginBottom">
      <p class="paddingP van-hairline--bottom">
        <span>会员折扣</span
        ><span
          @click="selectVipDiscount"
          :style="{ color: discount_price == 0.0 ? 'gray' : '' }"
          >{{ discount_price == 0.0 ? discount_tips : "-￥" + discount_price
          }}<van-icon name="arrow"
        /></span>
      </p>
      <p class="paddingP" @click="couponSelectClick">
        <span>优惠券</span
        ><span
          ><font v-if="!couponInfo.useCondition" style="color:gray"
            >可用优惠券{{ couponLength }}张</font
          ><font v-else>{{ couponInfo.useCondition }}</font>
          <van-icon name="arrow"
        /></span>
      </p>
    </div>
    <div class="remarks-div marginBottom">
      <p class="remarks-div-p"><span>备注（选填）</span></p>
      <van-field
        v-model="remarksContent"
        type="textarea"
        rows="1"
        autosize
        :border="false"
        placeholder="如有特殊情况请提前告知..."
      />
    </div>
    <div class="privacy-div marginBottom">
      <p class="paddingP">
        <span
          ><svg-icon icon-class="protect" class="protectIcon"></svg-icon
          >号码保护</span
        >
      </p>
      <p class="tips">隐藏手机号，保护隐私，保驾护航</p>
    </div>
    <div class="operate-div">
      <template v-if="isCompleteTotalPrice">
        <span>价格计算中</span>
        <loading />
      </template>
      <template v-else>
        <span>支付费用<font> ￥{{ totalPrice }}元</font></span >
      </template>
      <span class="operateBtn" @click="confirmOrder">立即下单</span>
    </div>
    <!-- 选择服务时间 -->
    <van-popup
      v-model="serveSelectTimeShow"
      closeable
      position="bottom"
      :style="{ minHeight: '50%' }"
      round
    >
      <div class="serveSelectTimePopup">
        <van-tabs
          v-model="timeActive"
          line-height="2"
          title-active-color="#ee0a24"
          title-inactive-color="#000"
          @change="appointment_select_time = ''"
        >
          <van-tab
            v-for="(item, index) in serveSelectTime"
            :key="index"
            :title="item.appointmentDateTitle"
            :name="item.appointmentDate"
          >
            <div class="timeList">
              <span
                :class="[
                  'time-li',
                  time == appointment_select_time ? 'time-li-select' : ''
                ]"
                @click="appointment_select_time = time"
                v-for="time in item.appointmentTime"
                :key="time"
                >{{ time }}</span
              >
            </div>
          </van-tab>
        </van-tabs>
        <div class="confirm-div">
          <p class="tips">实际到达时时间可能会有30分钟浮动</p>
          <p class="confirmBtn" @click="confirmSelectTime">确认预约</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getLsServeDetailOut } from "@/api/index";
import { getLsArtificerAppointmentTimeOutList, chooseArtificer } from "@/api/service";
import { getweekday, getToDay } from "@/utils/index";
import { getLsMyCouponByPage } from "@/api/my";
import { orderAppointmentOrder, addOrder, getIsOutRange } from "@/api/order";
import { throttle } from "@/utils/index"
import { Dialog } from "vant";
import { getCityCode } from "@/api/index";
import loading from "@/components/loading";

export default {
  data() {
    return {
      isCompleteTotalPrice: false,
      appointmentOrderData: "",
      addressInfo: {},
      timeActive: 0,
      travel_price: 0,
      travel_id: "",
      serveSelectTime: [],
      couponLength: 0,
      serve_name: "",
      serve_id: "",
      serve_url: "",
      appointment_time: "",
      appointment_select_time: "",
      appointment_time_title: "",
      details: "",
      remarksContent: "",
      purchase_num: 1,
      couponInfo: "",
      isPurchaseVip: false,
      serveSelectTimeShow: false,
      isAlReadyVip: false,
      isClickPlay: false,
      vip_id: "",
      openVipPrice: "",
      vipDiscountRatio: 0,
      rentalDidiTraffic: {},
      busSubwayTraffic: {},
      discount_tips: "还不是会员",
      cityCode:'',
      requestNumber:0,
    };
  },
  components:{
    loading
  },
  computed: {
    // 会员折扣计算
    discount_price() {
      if ((this.isAlReadyVip || this.isPurchaseVip) && !this.couponInfo) {
        return (
          (
            this.details.servePrice -
            this.details.servePrice * this.vipDiscountRatio
          ).toFixed(2) || 0.0
        );
      } else {
        return 0.0;
      }
    },
    // 总金额计算
    totalPrice() {
      let price = Number(
        this.details.servePrice * this.purchase_num + this.travel_price
      );
      if (this.isAlReadyVip) {
        price = price - this.discount_price;
      } else if (!this.isAlReadyVip && this.isPurchaseVip) {
        price = price - this.discount_price + this.openVipPrice;
      }
      if (this.couponInfo) {
        price = price - this.couponInfo.discountAmount;
      }
      return price >= 0 ? price.toFixed(2) : 0.01;
    }
  },
  activated() {
    this.serve_id = this.$route.query.serve_id;
    this.serve_name = this.$route.query.serve_name;
    this.serve_url = this.$route.query.serve_url;
    this.appointment_time = this.$route.query.appointment_time;
    this.appointment_time_title = this.$route.query.appointment_time_title;
    this.timeActive = this.$route.query.timeActive;
  },
  deactivated(){
    this.requestNumber = 0
  },
  methods: {
    getRealCityCode(code){
      getCityCode({ locationCode: code }).then(
          res => {
            this.cityCode = res.data
          }
        );
    },
    // 确认下单
    confirmOrder() {
      if (!this.addressInfo.appointmentAddress) {
        this.$toast("请选择服务地址");
      } else if(this.isClickPlay){
        return false
      } else if(this.serve_id == '' || this.serve_name == ''){
        this.$toast("请选择服务技师！");
        return false;
      }else{
        this.isClickPlay = true

        let cityCode;
        if(this.addressInfo.cityCode){
          cityCode = this.addressInfo.cityCode
        }else{
          cityCode = localStorage.getItem("cityCode") || "110100"
        }
        
        let data = {
            artificerId: this.serve_id,
            appointmentTime:
              this.timeActive + " " + this.appointment_time + ":00",
            appointmentAddress: "",
            appointmentAddressLongitude: this.addressInfo
              .appointmentAddressLongitude,
            appointmentAddressLatitude: this.addressInfo
              .appointmentAddressLatitude,
            appointmentPersonName: this.addressInfo.appointmentPersonName,
            appointmentPhone: this.addressInfo.appointmentPhone,
            appointmentSex: this.addressInfo.sex,
            trafficWay: this.travel_id !==''?this.travel_id : "3",
            serveId: this.$route.query.id,
            num: 1,
            remarks: this.remarksContent,
            isOpenVip: this.isPurchaseVip ? "1" : "2",
            vipId: this.isPurchaseVip || this.isAlReadyVip ? this.vip_id : "",
            couponId: this.couponInfo ? this.couponInfo.couponId : "",
            couponRowId: this.couponInfo ? this.couponInfo.memberCouponId : "",
            isCouponOrVip: "3", // 1 优惠券 2 vip 3 无
            umsAddressId: this.addressInfo.umsAddressId,
            cityCode:cityCode
          };
          if (this.addressInfo.province !== this.addressInfo.city) {
            data.appointmentAddress =
              this.addressInfo.province +
              this.addressInfo.city +
              this.addressInfo.area +
              this.addressInfo.appointmentAddress;
          } else {
            data.appointmentAddress =
              this.addressInfo.city +
              this.addressInfo.area +
              this.addressInfo.appointmentAddress;
          }
          if (this.couponInfo) {
            data.isCouponOrVip = "1";
          } else if (this.isPurchaseVip || this.isAlReadyVip) {
            data.isCouponOrVip = "2";
          }
          addOrder(data).then(res => {
            this.isClickPlay = false
            if (res.data) {
              this.$router.replace({
                path: "/order/details",
                query: { order_id: res.data }
              });
            }
          });
      }
    },
    // 点击选择优惠券
    couponSelectClick() {
      if (this.couponLength == 0) {
        return false;
        this.$toast("暂无可用优惠券");
      } else {
        this.$router.push({
          path: "/my/coupon",
          query: {
            serveId: this.$route.query.id,
            servePrice:this.details.servePrice
          }
        });
      }
    },
    // 点击选择服务地址
    addressClick() {
      this.$router.push({
        path: "/my/service-address",
        query: { from: "order" }
      });
    },
    // 确认选择预约时间
    confirmSelectTime() {
      if (!this.appointment_select_time) {
        this.$toast("请选择预约时间");
      } else {
        this.appointment_time = this.appointment_select_time;
        this.appointment_time_title =
          getToDay(this.timeActive) + "天" + getweekday(this.timeActive);
        this.appointment_select_time = "";
        this.serveSelectTimeShow = false;
        // 更换时间参数
        this.$router.replace({
          path:"/order/confirmOrder",
          query:{
            ...this.$route.query,
            timeActive: this.timeActive,
            appointment_time_title: getToDay(this.timeActive) + "天" + getweekday(this.timeActive),
            appointment_time: this.appointment_time
          }
        })
        this.orderAppointmentDataOrder(this.addressInfo.id)
      }
    },
    // 获取服务项目详情
    getLsServeDetailOut() {
      let cityCode;
      if(this.addressInfo.cityCode){
        cityCode = this.addressInfo.cityCode
      }else{
        cityCode = localStorage.getItem("cityCode") || "110100"
      }
      let params = {
        serveId: this.$route.query.id,
        cityCode:cityCode
      };
      getLsServeDetailOut(params).then(res => {
        this.details = res.data;
        let couponData = {
          blnStatus: 2,
          pageIndex: 1,
          pageSize: 10,
          serveId: this.$route.query.id
        };
        getLsMyCouponByPage(couponData).then(res => {
          this.couponLength = res.data.total;
        });
      });
    },
    // 获取预约下单信息
    orderAppointmentDataOrder(addressId) {
      // console.log(sessionStorage.getItem('orderSelectAddress'))
      let cityCode;
      if(addressId){
        cityCode = this.addressInfo.cityCode
      }else{
        cityCode = localStorage.getItem("cityCode") || "110100"
      }
      let data = {
        artificerId: this.$route.query.serve_id,
        umsAddressId: addressId || null,
        appointmentAddressLongitude:
          this.addressInfo.appointmentAddressLongitude || "",
        appointmentAddressLatitude:
          this.addressInfo.appointmentAddressLatitude || "",
        cityCode: cityCode,
        appointmentTime:
          this.$route.query.timeActive +
          " " +
          this.$route.query.appointment_time +
          ":00"
      };
      orderAppointmentOrder(data).then(res => {
        if (res.data) {
          this.appointmentOrderData = res.data;
          this.isAlReadyVip = res.data.isVip == 1 ? true : false;
          if (this.$route.query.type == 1 && !this.isAlReadyVip) {
            this.isPurchaseVip = true;
          }
          this.addressInfo = res.data.appointmentAddress;
          // this.addressInfo = res.data.appointmentAddress;
          this.vip_id = res.data.vipInfo.vipId;
          this.openVipPrice = res.data.vipInfo.vipPrice;
          this.vipDiscountRatio = res.data.vipInfo.discount;
          this.busSubwayTraffic = res.data.busSubwayTraffic;
          this.rentalDidiTraffic = res.data.rentalDidiTraffic;
          try {
            if (
              res.data.busSubwayTraffic.status == 1 &&
              res.data.rentalDidiTraffic.status == 1
            ) {
              this.travel_id = res.data.busSubwayTraffic.trafficWay;
              this.travel_price = res.data.busSubwayTraffic.trafficAmount;
            } else if (
              res.data.busSubwayTraffic.status == 1 &&
              res.data.rentalDidiTraffic.status == 2
            ) {
              this.travel_id = res.data.rentalDidiTraffic.trafficWay;
              this.travel_price = res.data.rentalDidiTraffic.trafficAmount;
            } else if (
              res.data.busSubwayTraffic.status == 2 &&
              res.data.rentalDidiTraffic.status == 1
            ) {
              this.travel_id = res.data.rentalDidiTraffic.trafficWay;
              this.travel_price = res.data.rentalDidiTraffic.trafficAmount;
            } else {
              this.travel_id = "";
              this.travel_price = "";
            }
          } catch (error) {
            this.$store.commit("loadingControl",false)
          }

          if(addressId != null){
            this.isCompleteTotalPrice = false;
          }

          if(this.requestNumber == 0 && res.data.isOutOfRange == 1){
            this.requestNumber++;
            this.$toast("地址已更换，当前技师不在服务范围内，系统正在为您重新挑选技师");
            this.$store.commit("loadingControl",false)
            setTimeout(() => {
              this.getchooseArtificer();
            }, 500)
          }else{
            this.isCompleteTotalPrice = false;
          }

        }

      });
    },
    // 服务人员点击
    serveUserSelect() {
      let cityCode;
      if(this.addressInfo.cityCode){
        cityCode = this.addressInfo.cityCode
      }else{
        cityCode = localStorage.getItem("cityCode") || "110100"
      }
      sessionStorage.setItem(
        "orderSelectAddress",
        JSON.stringify({
          ...this.addressInfo,
          id:this.addressInfo.umsAddressId,
          lat:this.addressInfo.appointmentAddressLatitude,
          lng:this.addressInfo.appointmentAddressLongitude,
          consigneeName:this.addressInfo.appointmentPersonName,
          consigneeMobile:this.addressInfo.appointmentPhone,
          gender:this.addressInfo.sex,
          province:this.addressInfo.province,
          city:this.addressInfo.city,
          area:this.addressInfo.area,
          detailAddress:this.addressInfo.appointmentAddress,
          cityCode:cityCode
        })
      );
      this.$router.push({
        path: "/selectAttendant",
        query: {
          serve_id: this.$route.query.id,
          lat: this.addressInfo.appointmentAddressLatitude,
          lng: this.addressInfo.appointmentAddressLongitude
        }
      });
    },
    // 服务时间点击
    serveTimeSelect() {
      if (!this.serve_id) {
        this.$toast("请先选择服务人员");
      } else {
        getLsArtificerAppointmentTimeOutList({
          artificerId: this.serve_id
        }).then(res => {
          res.data.forEach(item => {
            item.appointmentDateTitle =
              getToDay(item.appointmentDate) +
              "天" +
              getweekday(item.appointmentDate);
            item.appointmentTime = item.appointmentTime!=''?item.appointmentTime.split(","):[];
          });
          this.serveSelectTime = res.data;
          this.appointment_select_time = this.appointment_time;
          this.serveSelectTimeShow = true;
        });
      }
    },
    // 开通VIP点击
    clickSelectPurchaseVip() {
      this.isPurchaseVip = !this.isPurchaseVip;
      if (this.isPurchaseVip) {
        this.couponInfo = "";
      }
    },
    // 点击使用会员折扣
    selectVipDiscount() {
      let _this = this;
      if (this.isAlReadyVip && this.couponInfo) {
        Dialog.confirm({
          width: "280",
          title: "提示",
          className: "dialogClassName",
          message: "是否确认使用会员折扣?\n使用会员折扣则取消优惠券的使用"
        })
          .then(() => {
            _this.couponInfo = "";
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    // 是否超出距离
    async isOutRange(umsAddressId){
      var res = await getIsOutRange({
        artificerId: this.$route.query.serve_id,      // 防止服务人员更换后，地址未及时更换，导致传空
        lat:this.addressInfo.appointmentAddressLatitude,
        lng:this.addressInfo.appointmentAddressLongitude
      })
      if(!res.data){
        this.$toast("地址已更换，当前技师不在服务范围内，系统正在为您重新挑选技师");
          this.$store.commit("loadingControl",false)
          setTimeout(() => {
            this.getchooseArtificer(umsAddressId);
          }, 500)
        }else{
          this.getLsServeDetailOut(); 
          this.orderAppointmentDataOrder(umsAddressId);
        }
    },
    // 获取技师列表
    getchooseArtificer(umsAddressId){
      let params = {
        serveId: this.$route.query.id,
        pageIndex: 1,
        pageSize: 10,
        latitude: this.addressInfo.appointmentAddressLatitude || "39.99735",
        longitude: this.addressInfo.appointmentAddressLongitude || "116.480073",
      }
      chooseArtificer(params).then(res => {
        this.$store.commit("loadingControl",false)
        if(res.data.records.length == 0 || res.data.records[0].isOutOfRange == 1){
          this.$toast("当前地址暂无可服务的技师，请重新选择地址");
          this.serve_name = ''
          this.serve_id = ''
          this.serve_url = ''
          this.appointment_time = ''
          this.timeActive = ''
          this.appointment_time_title = ''
          this.serveSelectTime = []
          this.appointment_select_time = ''
          this.serveSelectTimeShow = false
          this.details.servePrice = 0
          this.travel_price = 0
          return
        }
        res.data.records.forEach(item => (item.showPopover = false));
        // 获取技师列表中的首个
        let firstAttendantInfo = res.data.records[0];
        this.serve_name = firstAttendantInfo.nickName;
        this.serve_id = firstAttendantInfo.artificerId;
        this.serve_url = firstAttendantInfo.avatar;
        this.appointment_time = firstAttendantInfo.earliestAppointmentTime;
        this.timeActive = firstAttendantInfo.earliestAppointmentDate;
        this.appointment_time_title =
          getToDay(firstAttendantInfo.earliestAppointmentDate) +
          "天" +
          getweekday(firstAttendantInfo.earliestAppointmentDate);

          
          this.$router.replace({
            path:"/order/confirmOrder",
            query:{
             ...this.$route.query,
             serve_name: firstAttendantInfo.nickName,
             serve_id: firstAttendantInfo.artificerId,
             serve_url: firstAttendantInfo.avatar,
             appointment_time: firstAttendantInfo.earliestAppointmentTime,
             appointment_time_title:
               getToDay(firstAttendantInfo.earliestAppointmentDate) +
               "天" +
               getweekday(firstAttendantInfo.earliestAppointmentDate)
            }
          })

        // // 更换了技师后，重新获取预约下单信息
        this.getLsServeDetailOut();
        // console.log(this.addressInfo)
        this.orderAppointmentDataOrder(umsAddressId);
      });
    },
  },
  
  beforeRouteLeave(to, from, next) {
    if (to.path == "/projectDetails" || to.path == "/attendantDetails") {
      Object.assign(this.$data, this.$options.data.call(this));
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      
      if (sessionStorage.getItem("attendantInfo")) {
        let attendantInfo = JSON.parse(sessionStorage.getItem("attendantInfo"));
        // BUG: 选择完技师后，无法更新新技师
        // vm.serve_name = attendantInfo.nickName;
        // vm.serve_id = attendantInfo.artificerId;
        // vm.serve_url = attendantInfo.avatar;
        // vm.appointment_time = attendantInfo.earliestAppointmentTime;
        // vm.appointment_time_title =
        //   getToDay(attendantInfo.earliestAppointmentDate) +
        //   "天" +
        //   getweekday(attendantInfo.earliestAppointmentDate);
        
        // to.query.serve_name = attendantInfo.nickName;
        // to.query.serve_id = attendantInfo.artificerId;
        // to.query.serve_url = attendantInfo.avatar;
        // to.query.appointment_time = attendantInfo.earliestAppointmentTime;
        // to.query.appointment_time_title = 
        //   getToDay(attendantInfo.earliestAppointmentDate) +
        //   "天" +
        //   getweekday(attendantInfo.earliestAppointmentDate);
        vm.$router.replace({
          path:vm.$route.path,
          query:{
            ...to.query,
            serve_name: attendantInfo.nickName,
            serve_id: attendantInfo.artificerId,
            serve_url: attendantInfo.avatar,
            appointment_time: attendantInfo.earliestAppointmentTime,
            timeActive: attendantInfo.earliestAppointmentDate,
            appointment_time_title: 
              getToDay(attendantInfo.earliestAppointmentDate) +
              "天" +
              getweekday(attendantInfo.earliestAppointmentDate)
          }
        })

        vm.serve_id = attendantInfo.artificerId;
        vm.serve_name = attendantInfo.nickName;
        vm.serve_url = attendantInfo.avatar;
        vm.appointment_time = attendantInfo.earliestAppointmentTime;
        vm.timeActive = attendantInfo.earliestAppointmentDate;
        vm.appointment_time_title = 
          getToDay(attendantInfo.earliestAppointmentDate) +
          "天" +
          getweekday(attendantInfo.earliestAppointmentDate);

        await vm.isOutRange(vm.addressInfo.umsAddressId);
        // vm.orderAppointmentDataOrder(vm.addressInfo.umsAddressId);
        
        sessionStorage.removeItem("attendantInfo");
      } else if (sessionStorage.getItem("orderSelectAddress")) {
        let orderSelectAddress = JSON.parse(
          sessionStorage.getItem("orderSelectAddress")
        );
        console.log('-------orderSelectAddress------')
        console.log(orderSelectAddress)
        vm.addressInfo.id = orderSelectAddress.id;
        vm.addressInfo.province = orderSelectAddress.province;
        vm.addressInfo.city = orderSelectAddress.city;
        vm.addressInfo.area = orderSelectAddress.area;
        vm.addressInfo.appointmentAddress = orderSelectAddress.detailAddress;
        vm.addressInfo.appointmentAddressLatitude = orderSelectAddress.lat;
        vm.addressInfo.appointmentAddressLongitude = orderSelectAddress.lng;
        vm.addressInfo.appointmentPersonName = orderSelectAddress.consigneeName;
        vm.addressInfo.appointmentPhone = orderSelectAddress.consigneeMobile;
        vm.addressInfo.sex = orderSelectAddress.gender;
        vm.addressInfo.cityCode = orderSelectAddress.cityCode;
        
        await vm.isOutRange(vm.addressInfo.id);
        // vm.getLsServeDetailOut(); 
        // vm.orderAppointmentDataOrder(vm.addressInfo.id);
        // // 选择完地址后，调用一次接口来获取判定技师是否超出范围
        
        sessionStorage.removeItem("orderSelectAddress");
      } else if (sessionStorage.getItem("couponInfo")) {
        vm.isPurchaseVip = false;
        vm.discount_price = 0.0;
        vm.discount_tips = "优惠券与会员折扣不可同时使用";
        vm.couponInfo = JSON.parse(sessionStorage.getItem("couponInfo"));
        sessionStorage.removeItem("couponInfo");
      } else {
        vm.isCompleteTotalPrice = true
        vm.getLsServeDetailOut();
        vm.orderAppointmentDataOrder();
      }
    });
  }
};
</script>
<style lang="less">
.confirmOrder {
  .remarks-div .van-cell {
    font-size: 0.28rem;
  }
}
</style>
<style lang="less" scoped>
.confirmOrder {
  min-height: 100vh;
  background: #f7f6f6;
  display: flex;
  flex-direction: column;
  .marginBottom {
    margin-bottom: 0.4rem;
    background: white;
  }
  .paddingP {
    padding: 0.4rem;
    display: flex;
    justify-content: space-between;
  }
  .address {
    background: white;
    display: flex;
    flex-direction: column;
    .addressInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.3rem 0.4rem;
      .name-p {
        font-weight: bold;
        font-size: 0.36rem;
      }
      .address-p {
        font-size: 0.28rem;
        color: gray;
        margin-top: 0.3rem;
      }
      .noAddressP {
        font-size: 0.4rem;
        color: gray;
        padding: 0.2rem;
      }
    }
    .lineIcon {
      width: 100%;
      height: 0.08rem;
      font-size: 0.2rem;
    }
  }
  .serveDetail {
    padding: 0.4rem;
    .serve-div {
      display: flex;
      align-items: center;
      .serveImg {
        width: 2rem;
        height: 2rem;
        border-radius: 6px;
      }
      .serve-name {
        font-weight: bold;
        font-size: 0.36rem;
      }
      .serve-info {
        flex: 1;
        margin-left: 0.3rem;
        .purchase_num-p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.2rem 0;
          .price {
            font-size: 0.4rem;
            font-weight: bold;
            color: orange;
          }
        }
      }
      .serve-time {
        .timeIcon {
          margin-right: 0.15rem;
        }
        color: gray;
      }
    }
    .vip-div {
      background: #f7f6f6;
      border-radius: 8px;
      display: flex;
      margin-top: 0.5rem;
      .vip-diamond-div {
        display: flex;
        flex-direction: column;
        flex: 1;
        .vip-diamond-span {
          width: 2rem;
          height: 0.6rem;
          line-height: 0.6rem;
          background: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #bd9b5b;
          font-size: 0.24rem;
          border-top-left-radius: 8px;
          border-bottom-right-radius: 8px;
          .diamond {
            width: 0.3rem;
            height: 0.3rem;
            margin-right: 0.15rem;
          }
        }
        .vip-tips-span {
          font-size: 0.28rem;
          color: #bd9b5b;
          padding: 0.2rem 0.2rem 0;
        }
        .vip-discount-span {
          color: gray;
          padding: 0.2rem 0.2rem 0.4rem;
          font-weight: bold;
          font-size: 0.32rem;
          font {
            color: red;
          }
        }
      }
    }
    .vip-select-div {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding-right: 0.4rem;
      justify-content: center;
      span:first-child {
        display: flex;
        font-size: 0.45rem;
        font-weight: bold;
        .van-checkbox {
          margin-left: 0.2rem;
        }
      }
      span:last-child {
        width: 1.8rem;
        height: 0.55rem;
        margin-top: 0.2rem;
        line-height: 0.55rem;
        text-align: center;
        background: rgb(214, 3, 3);
        color: #fff;
        border-radius: 0.2rem 0 0.3rem 0.2rem;
        font-size: 0.24rem;
      }
    }
  }
  .serve-select-div {
    background: white;
    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem;
      span {
        flex: 1;
        &:last-child {
          text-align: right;
        }
      }
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .serveUrl {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        padding: 0 5px;
      }
    }
  }
  .travel-div {
    .travel-tips {
      color: orange;
      padding: 0 0.4rem 0.2rem;
    }
    .travel-list-div {
      font {
        border: solid 1px;
        font-size: 0.28rem;
        padding: 0.1rem 0.2rem;
        border-radius: 4px;
        margin-left: 0.2rem;
      }
      .travelSelect {
        color: rgb(214, 3, 3);
        border: solid 1px rgb(214, 3, 3);
      }
    }
  }
  .remarks-div {
    .remarks-div-p {
      padding: 0.4rem 0.4rem 0;
    }
  }
  .serveSelectTimePopup {
    min-height: 50vh;
    padding-top: 0.8rem;
    display: flex;
    flex-direction: column;
    .van-tabs {
      flex: 1;
    }
    .timeList {
      display: flex;
      flex-wrap: wrap;
      max-height: 6rem;
      overflow: auto;
      .time-li {
        background: #f1f1f1;
        padding: 8px 0;
        width: 24%;
        text-align: center;
        border-radius: 0.4rem;
        margin-top: 0.4rem;
        &:not(:nth-child(4n)) {
          margin-right: 1%;
        }
      }
      .time-li-select {
        background: rgb(238, 10, 36);
        color: #fff;
      }
    }
    .confirm-div {
      padding: 0.3rem;
      text-align: center;
      .tips {
        font-size: 0.24rem;
        margin-bottom: 0.3rem;
        color: orange;
      }
      .confirmBtn {
        font-size: 0.38rem;
        font-weight: bold;
        color: #fff;
        background: rgb(238, 10, 36);
        padding: 15px 0;
        border-radius: 0.6rem;
      }
    }
  }
  .privacy-div {
    margin-bottom: 2rem;
    .protectIcon {
      font-size: 0.4rem;
      margin-right: 0.2rem;
    }
    .tips {
      font-size: 0.28rem;
      padding: 0 0.4rem 0.4rem;
      color: gray;
    }
  }

  .operate-div {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
    display: flex;
    span:first-child {
      flex: 1;
      padding-left: 0.4rem;
      font-size: 0.4rem;
      display: flex;
      align-items: center;
      font {
        font-size: 0.5rem;
        color: rgb(214, 3, 3);
      }
    }
    .operateBtn {
      width: 3.5rem;
      height: 1.5rem;
      font-weight: bold;
      font-size: 0.5rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(214, 3, 3);
    }
  }
}
</style>
